import React from "react";
import '../../styles/semantic.min.css';
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import GreetingsBVDD from "../../components/about-us/GreetingsBVDD";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import PageHeader from "../../components/utils/PageHeader";
import {Container, Grid, Segment} from "semantic-ui-react";
import AboutUsNavigation from "../../components/navigation/AboutUsNavigation";
import DesktopContainer from "../../container/DesktopContainer";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import layout from "../../templates/layout.module.css";

export default () =>
    <LayoutContainer>
        <SEO title={'Grußwort BVDD'}/>

            <MobileTabletContainer>
                <MainNavigation/>
                <PageHeader title={'Grußwort'}
                            subHeader={'Grußwort des Präsidenten des Bundesverbandes Deutscher Dermatologen e. V. anlässlich der Gründung der Deutschen Stiftung Kinderdermatologie'}/>
                <MobileBgWrapper>
                    <Container>
                        <Segment>
                    <GreetingsBVDD/>
                        </Segment>
                    </Container>
                </MobileBgWrapper>
                <Footer/>
            </MobileTabletContainer>
            <DesktopContainer>
                <div className={layout.pageWrapper}>
                <MainNavigation/>
                <Container fluid>
                <PageHeader title={'Grußwort'}
                            subHeader={'Grußwort des Präsidenten des Bundesverbandes Deutscher Dermatologen e. V. anlässlich der Gründung der Deutschen Stiftung Kinderdermatologie'}/>
                <Grid centered columns={2}>
                    <Grid.Column width={11}>
                            <GreetingsBVDD/>
                        <AboutUsNavigation/>
                    </Grid.Column>
                    <Grid.Column width={4}/>
                </Grid>
                </Container>
                <Footer/>
                </div>
            </DesktopContainer>


    </LayoutContainer>