import React from 'react';
import {Image, Segment,} from 'semantic-ui-react';
import profileImg1 from "../../assets/stroemer.jpg";
import bvdd from "../../assets/bvdd.jpg";
import componentStyles from "../../templates/default.module.css";

const GreetingsBVDD = () => (
    <Segment>
        <Image src={bvdd} floated={'right'} size={'small'} className={componentStyles.greetingsImg}/>
        <Image src={profileImg1} floated={'left'} size={'medium'} className={componentStyles.greetingsImg}/>
        <p>Sehr geehrte Damen,</p>
        <p>sehr geehrte Herren,</p>
        <p>die Qualität einer bürgerlichen Gesellschaft in einem Sozialstaat erkennt man auch an der Fürsorge, die sie ihren Kranken unabhängig deren Lebensalter entgegenbringt. Leider gibt es heute noch deutliche Defizite hinsichtlich der notwendigen Strukturen, wenn man den Blick auf die Versorgung hautkranker Kinder lenkt.</p>
        <p>Obwohl an den deutschen Hautkliniken auf einem weltweit hohen Qualitätsniveau und breitem Leistungsspektrum behandelt werden kann, fehlen noch kindgerechte Angebote und ausreichend speziell auf die Kinder zugeschnittene Ausbildungskonzepte für Hautärzte.
            Die speziellen Anforderungen an die Unterbringung der kleinen Patienten und die Besonderheiten der Behandlung insbesondere den ersten Lebensjahren muss weiter in den Focus gerückt werden.</p>
        <p>Die Verzahnung von kinderärztlicher und dermatologischer Betreuung muss ausgebaut werden, um eine flächendeckende Versorgung auf dem gleichen Niveau sicherstellen zu können, wie sie für Erwachsene längst Realität ist. Hierfür müssen in der Zukunft ausreichende Mittel zur Verfügung gestellt werden. Wir brauchen kinderdermatologische Spezialkliniken für unsere schwer hautkranken Kinder. Die Eltern unserer kleinen Patienten müssen wohnortnah kompetente Ansprechpartner finden können in der ambulanten wie stationären Betreuung.</p>
        <p>Hautkranke Kinder wollen kein verschämtes Wegschauen. Sie wollen aber auch kein mitleidiges Hinschauen. Sie wollen das, was für hautkranke Erwachsene im Gesundheitssystem längst gewährleistet ist; eine leistungsstarke Krankenversorgung.</p>
        <p>Als Präsidenten des Bundesverbandes Deutscher Dermatologen e.V. möchte ich meine große Freude angesichts der Errichtung der Deutschen Stiftung Kinderdermatologie von Frau Dr. Inselkammer ausdrücken und mich bei allen bedanken, die sich dieser schwierigen Aufgabe gestellt haben und mit ihrem Engagement zum Gelingen einer Förderung von hautkranken Kindern und Jugendlichen beitragen.</p>
        <p>Ich wünsche der Deutschen Stiftung Kinderdermatologie und all ihren Mitgliedern und Helfern für die Zukunft einen langen Atem, viel Glück bei der Suche nach Unterstützern, ein offenes Ohr bei den Entscheidungsträgern in Politik und Gesundheitswesen, ein hohes Maß an öffentlicher Wahrnehmung und Kraft für die Anstrengungen auf dem Weg für eine bessere Versorgung unserer hautkranken Kinder.</p>
        <p>Mit freundlichen Grüßen</p>
        <p>Dr. med. Klaus Strömer</p>
    </Segment>

);

export default GreetingsBVDD;